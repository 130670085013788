.ant-modal-content {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 24px;
  padding-top: 32px;
}

.login-offboarding-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 13px;
}

.login-offboarding-icon {
  width: 130px;
  height: 130px;
  margin-bottom: 8px;
}

.login-offboarding-title {
  font-family: poppins;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-align: center;
  color: #262338;
}

.login-offboarding-content {
  font-family: poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
  color: #4E4B66;
}