.payment-status-overlay-container {
    display: flex;
    flex-grow: 1;
    height: 200;
    flex-direction: column;
    align-items: center;
    padding:16px 16px;
}

.payment-status-overlay-icon {
    width: 15vw;
    height: auto;
    max-width: 100px;
    margin-top: 16px;
}

.payment-status-overlay-info-container {
    border-radius: 8px;
    padding:16px 16px;
    background-color: #F7F7FC;
    margin-bottom: 16px;
    text-align: center;
}

.payment-status-overlay-title {
    margin: 0 0;
    margin-top:16px;
    text-align: center;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 700;
}

.payment-status-overlay-detail-container {
    margin-bottom: 16px;
    width:100%;
    display: flex;
    font-family: 'poppins';
    align-items: center;
}

.payment-status-overlay-label {
    flex-grow: 1;
    margin: 0;
    padding: 0;
}