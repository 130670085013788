.review-payment-form-overlay-text-container {
    display: flex;
    margin-bottom: 16px;
}

.review-payment-form-overlay-label-item {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    font-family: 'poppins';
    font-size: 15px;
    font-weight: 500;
}

.review-payment-form-overlay-title {
    margin: 0;
    padding: 0;
    font-family: 'poppins';
    font-size: 15px;
    font-weight: 700;
}

.review-payment-form-overlay-due {
    margin: 0;
    padding: 0;
    font-family: 'poppins';
    font-size: 15px;
    font-weight: 500;
}

.review-payment-form-overlay-total {
    margin: 0;
    padding: 0;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 700;
}

.review-payment-form-overlay-container {
    max-height: 90vh;
    overflow: auto;
    padding: 16px;
}

.review-payment-form-overlay-merchant-container {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.review-payment-form-overlay-merchant-text {
    font-family: poppins;
    font-weight: 700;
    font-size: 15px;
    margin-top: 8px;
}

.review-payment-form-overlay-merchant-img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    border: 0.5px solid #EFEFEF;
}

.review-payment-form-overlay-tnc-container {
    text-align: center;
    font-family: poppins;
    font-weight: 600;
}

.review-payment-form-overlay-tnc-text-1 {
    margin-right: 8px;
}

.review-payment-form-overlay-tnc-button-1 {
    background-color: inherit;
    border: none;
    cursor: pointer;
    font-family: poppins;
    font-weight: 600;
}