.home-dashboard-screen-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px
}

.home-dashboard-screen-wallet-container {
    margin-top: 16px;
    width: 100%;
}

.home-dashboard-screen-available-limit-text {
    font-family:poppins;
    font-weight: 600;
    font-size: 11px;  
    color: #6E7191;  
    margin: 0;
    padding: 0;
}

.home-dashboard-screen-integer {
    font-family:poppins;
    font-weight: 400;
    font-size:  40px;  
    color: #262338;  
    margin: 0;
    padding: 0;
}

.home-dashboard-screen-cents {
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    color: #262338;
    margin: 0;
    padding: 0;
}

.home-dashboard-screen-max-limit {
    font-family: poppins;
    font-weight: 600;
    font-size: 11px;
    color: #4E4B66;
    margin: 0;
    padding: 0;
}

.home-dashboard-screen-available-suppliers-text-container {
    display: flex;
    justify-content: flex-start;
    max-width: 600px;
    width: 100%;
    margin: 24px 0px 16px 0px;
  }

.home-dashboard-screen-available-suppliers-text {
    font-family: poppins;
    font-weight: 600;
    font-size: 13px;
}

.home-dashboard-screen-scrollable-container {
    overflow: auto;
    height: 200px;
    max-width: 600px;
    width: 100%;
    flex-grow: 1;
}

.home-dashboard-screen-scrollable-container-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
}

.home-dashboard-screen-scrollable-flex-container {
    max-width: 600px;
    width: 100%;
    flex-grow:  1;
    display: flex;
    flex-direction: column;
}

.home-dashboard-scree-available-suppliers-list {
    max-width: 600px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.home-dashboard-scree-no-elista-title {
    font-family: poppins;
    font-size: 17px;
    font-weight: 700;
}

.home-dashboard-scree-no-elista-subtitle {
    font-family: poppins;
    font-size: 13px;
    font-weight: 400;
}

.home-dashboard-scree-warning-icon {
    width: 80px;
    height: 80px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.home-dashboard-screen-no-elista-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px
}

.home-dashboard-screen-my-number {
    text-align: right;
    font-family: poppins;
    font-weight: 500;
    font-size: 10px;
    margin: 0;
    padding: 0;
    display: block;
}

.home-dashboard-screen-mobile-number {
    text-align: right;
    font-family: poppins;
    font-size: 12px;
    margin: 0;
    padding: 0
}

.home-dashboard-screen-mobile-number-container {
    width: 100%;
    text-align: right; 
    margin-right: 8px;
}

.home-dashboard {
    width: 100%;
    height:calc(100% - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.home-dashboard::-webkit-scrollbar {
    display: none;
}

.home-dashboard-offboarding-container {
    max-width: 600px;
    background-color: #FFF3E0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin: 16px;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.home-dashboard-offboarding-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 13px;
}

.home-dashboard-offboarding-icon {
    width: 61px;
    height: 61px;
}

.home-dashboard-offboarding-title {
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #CC4D23;
}

.home-dashboard-offboarding-content {
    font-family: poppins;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #262338;
}

.home-dashboard-download-icon {
    width: 16px;
    height: 16px;
}