.pay-dist-supp-form-screen-container {
    flex-grow: 1;
    border: 2px solid #EFEFEF;
    width: 100%;
    max-width: 600px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
}

.pay-dist-supp-form-screen-item-container {
    border-radius: 8px;
    margin: 8px;
    margin-bottom: 16px;
    font-size: 12px;
}

.pay-dist-supp-form-screen-list-container {
    flex-grow: 1;
    overflow-y: auto;
    height: 0;
}

.pay-dist-supp-form-screen-merchant-container {
    border: 0.5px solid #EFEFEF;
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
    margin: 0px 16px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.pay-dist-supp-form-screen-icon {
    width: 40px;
    height: 40px;
}

.pay-dist-supp-form-screen-merchant-name {
    flex-grow: 1;
    align-items: left;
    margin-left: 16px;
    font-weight: 400;
    font-family:poppins;
    text-align: left;
    font-size: 13px;
}

.pay-dist-supp-form-screen-available-limit {
    font-size: 40px;
    font-weight: 500px;
    font-family: 'poppins';
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pay-dist-supp-form-screen-max-limit {
    font-size: 11px;
    font-weight: 600;
    font-family: 'poppins';
    margin: 0
}



