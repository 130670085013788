.distributor-supplie-item-container {
    border: 1px solid;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 4px; 
    padding: 1em; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
}

.distributor-supplie-item-logo {
    height: 40px;
    aspect-ratio: 1;
    border: 1px solid;
    border-radius: 8px;
}

.distributor-supplie-item-label {
    flex-grow: 1;
    margin: 0px 11px;
    text-align: left;
    object-fit: contain;
    font-family: poppins;
    font-size: 13px;
    font-weight: 400;
     
}
