.gs-text-input-label {
    font-family: poppins;
    font-weight: 600;
    text-align:  left;
    font-size: 13;
    margin-bottom: 4px;
}

.gs-text-input-error {
    font-family: poppins;
    font-weight: 400;
    text-align:  left;
    font-size: 11;
    color: red;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


.gs-text-input-special-text {
    font-family: poppins;
    font-weight: 600;
    text-align:  left;
    font-size: 11;
}